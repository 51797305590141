import React, { createContext, useContext, useReducer, useEffect } from "react"
export const localStorageContext = createContext()

//TODO: how to avoid equal keys in separate reducers?

let reducers = {}
let initialState = {}
let combinedReducer = null

const DOMReady = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
)

export const wrapRootElement = ({ element }) => {
  return (
    <LocalStorageContextProvider keyName={"state"}>
      {element}
    </LocalStorageContextProvider>
  )
}

export const useLocalStorageReducer = key => {
  const { state, dispatch } = useContext(localStorageContext)
  return [state[key], dispatch]
}

const hasLocalStorage = () => {
  let storage
  try {
    storage = window["localStorage"]
    let x = "__test-local-storage__"
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    )
  }
}

const combineReducers = redObj => {
  return (state, action) =>
    Object.keys(redObj).reduce(
      (acc, prop) => ({ ...acc, [prop]: redObj[prop](acc[prop], action) }),
      state
    )
}

export const addReducer = (reducerName, reducer, reducerInitialState) => {
  let newReducer = {}
  newReducer[reducerName] = reducer
  reducers = { ...reducers, ...newReducer }
  combinedReducer = combineReducers(reducers)
  initialState = { ...initialState, [reducerName]: reducerInitialState }
}

const useLocalStorageReducer_ = (
  reducer,
  defaultState,
  storageKey,
  init = null
) => {
  //get the hook's return value from the local state if available,
  //or from defaultState if not
  const hookRetVal = useReducer(reducer, defaultState, defaultState => {
    const persisted =
      DOMReady && hasLocalStorage()
        ? getLocalStorage(storageKey, defaultState)
        : null
    // if (!persisted) console.log("not persisted!!!");
    const useReducerRetVal =
      persisted !== null
        ? persisted
        : init !== null
        ? init(defaultState)
        : defaultState
    return useReducerRetVal
  })

  //when the state changes -> save it to localstorage
  useEffect(() => {
    if (DOMReady && hasLocalStorage()) {
      setLocalStorage(storageKey, hookRetVal[0])
    }
  }, [storageKey, hookRetVal])

  return hookRetVal
}

export const LocalStorageContextProvider = props => {
  const [state, dispatch] = useLocalStorageReducer_(
    combinedReducer,
    initialState,
    props.keyName
  )
  return (
    <localStorageContext.Provider value={{ state, dispatch }}>
      {props.children}
    </localStorageContext.Provider>
  )
}

const setLocalStorage = (key, value) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    console.log(e)
  }
}

const getLocalStorage = (key, initialValue) => {
  try {
    const value = window.localStorage.getItem(key)
    return value ? JSON.parse(value) : initialValue
  } catch (e) {
    // if error, return initial value
    return initialValue
  }
}

const useLocalStorage = (key, initialValue) => {
  console.trace()
  const { state, setState } = useContext(localStorageContext)

  let value = state[key]

  if (!value) {
    try {
      // Get from local storage by key
      const item =
        DOMReady && hasLocalStorage() ? getLocalStorage(key, state) : null
      // Parse stored json or if none return initialValue
      value = item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      return initialValue
    }
  }

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have the same API as useState
      const valueToStore = value instanceof Function ? value(state[key]) : value
      // Save state
      setState(prev => ({ ...prev, [key]: valueToStore }))

      // Save to local storage
      if (DOMReady && hasLocalStorage()) {
        setLocalStorage(key, valueToStore)
      }
    } catch (error) {
      // TODO: handle errors
      console.log(error)
    }
  }

  return [value, setValue]
}

export default useLocalStorage
